import React from 'react';
import { useEffect, useState } from 'react';
import { axioPrivateLogin } from '../../api/axios';
import { Remarkable } from 'remarkable';

const md = new Remarkable();

const PrivacyPolicy = () => {
    const [content, setContent] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axioPrivateLogin.get('/documents/privacy-policy');
                setContent(md.render(response.data.content));
            } catch (err) {
                setError('Kunne ikke hente privatlivspolitikken');
            }
        };
        
        fetchDocument();
    }, []);

    if (error) return <div className="p-8 text-red-500">{error}</div>;
    
    return (
        <div className="container mx-auto p-8">
            <h1 className="text-2xl font-bold mb-6 text-left">Privatlivspolitik</h1>
            <div 
                className="prose prose-sm max-w-none text-left text-black"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
};

export default PrivacyPolicy; 