import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import IsAuth from '../../utillityComponents/IsAuth';
import PacmanLoader from "react-spinners/PacmanLoader";
import moment from 'moment';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function UpdateEvent(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)
  const axiosPrivat = useAxiosPrivate()

  const [photoFile, setPhotoFile] = useState(null);
  const [eventName, setEventName] = useState('');
  const [orgSlug, setOrgSlug] = useState('');
  const [eventImg, seteventImg] = useState('');
  const [orgName, setOrgName] = useState(false);
    const [eventSlug, setEventSlug] = useState('');
    const [sportstimingEventApiKey, setSportstimingEventApiKey] = useState('');
  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventDescription, setEventDescription] = useState('');
  const [canSignUpForMembership, setCanSignUpForMembership] = useState(null);
  const [publicDonation, setPublicDonation] = useState(null);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [hideOnFrontpage, setHideOnFrontpage] = useState(null);
  const [isPublic, setIsPublic] = useState(null);
  const [allowSignUp, setAllowSignUp] = useState(null);
  const [activateSportstiming, setActivateSportstiming] = useState(false);
  const [sportstimingEventId, setSportstimingEventId] = useState(null);
  const [allowDelete, setAllowDelete] = useState(false);
  const [groupEvents, setGroupEvents] = useState([]);
  const [groupEventId, setGroupEventId] = useState(null);
  const [confirmIportSportstiming, setConfirmIportSportstiming] = useState(false);
    const [eventClosedAt, setEventClosedAt] = useState(null);
    const [afslutEventet, setAfslutEventet] = useState(false);


  useEffect(() => {
    setEventName(eventName)
    if (props.old_event !== undefined) {
      setHideOnFrontpage(null)
      setAllowSignUp(null)
      setActivateSportstiming(false)
      setConfirmIportSportstiming(false)
      setSportstimingEventId(null)
      setIsPublic(null)
      setAllowDelete(false)
      setCanSignUpForMembership(null)
      setPublicDonation(null)
      setPhotoFile(null)
      setEventStartTime(null)
      setEventEndTime(null)
      setEventDescription('')
      setEventName('')
      setOrgSlug('')
      setOrgName(false)
      setEventSlug('')
      seteventImg('')
        setSportstimingEventApiKey('')
      setEventClosedAt(null)
        setAfslutEventet(false)
      getAllEventGroups()
      getEvent()
    }
    if (props.show) {
        setOpen(props.show);
    }
    // eslint-disable-next-line
  }, [props.show])
  

  const getEvent = async ( slug ) => {
    try {
      const response = await uploadAxios.get(process.env.REACT_APP_API_BASEURL + "/event/get/"+props.old_event);
      setEventName(response.data.name)
      setOrgSlug(response.data.org_slug)
      setEventSlug(response.data.slug)
      setEventStartTime(response.data.event_start_date)
      setEventEndTime(response.data.event_end_date)
      setOrgName(response.data.org.name)
      seteventImg(response.data.img)
      setEventDescription(response.data.description)
      setCanSignUpForMembership(response.data.can_sign_up_for_membership)
      setHideOnFrontpage(response.data.hide_on_frontpage)
      setAllowSignUp(response.data.has_public_sign_up)
      setIsPublic(response.data.is_public)
      setGroupEventId(response.data.event_group_id)
      setShowCheckbox(true)
      setPublicDonation(response.data.public_donations)
      setActivateSportstiming(response.data.sportstiming_enabled)
      setSportstimingEventId(response.data.sportstiming_event_id)
      setEventClosedAt(response.data.event_closed_at)
        setSportstimingEventApiKey(response.data.sportstiming_client_secret)
    } catch (err) {
        console.error(err);
    }
  }

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setPhotoFile(file[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("name", eventName);
    formData.append("org_slug", orgSlug);
    formData.append("can_sign_up_for_membership", canSignUpForMembership);
    formData.append("description", eventDescription);
    formData.append("slug", eventSlug);
    formData.append("event_start_date", new Date(eventStartTime).toISOString().replace(/.000/, ""));
    formData.append("event_end_date", new Date(eventEndTime).toISOString().replace(/.000/, ""));
    formData.append("event_group_id", groupEventId);
    formData.append("public_donations", publicDonation);
    formData.append("sportstiming_enabled", activateSportstiming);
    formData.append("sportstiming_secret", sportstimingEventApiKey);

    if (afslutEventet === true) {
        formData.append("event_closed_at", true);
    }

    if (sportstimingEventId !== null) {
        formData.append("sportstiming_event_id", sportstimingEventId);
    }

    if (photoFile !== null) {
      formData.append("img", photoFile);
    }
    if (hideOnFrontpage !== null) {
      formData.append("hide_on_frontpage", hideOnFrontpage);
    }
    if (allowSignUp !== null) {
      formData.append("has_public_sign_up", allowSignUp);
    }

    if (isPublic !== null) {
      formData.append("is_public", isPublic);
    }

    const saveEdit = async () => {
       try {
          setLoading(true);
            await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/event/update/'+props.old_event, formData
            );

            isMounted && setOpen(false)
            setOrgName(false)
        } catch (err) {
            console.error(err);
        }  
      setLoading(false);
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  const deleteEvent = async ( eSlug ) => {
    try {
      if (allowDelete === true) {
        setLoading(true);
        await uploadAxios.delete(process.env.REACT_APP_API_BASEURL + '/event/delete/'+eSlug);

        setLoading(false)
        setOpen(false)
      } else {
        alert("Sæt kryds i bekræft sletning for at slette eventet.")
      }
    } catch (err) {
        console.error(err);
    }
  }

const importSportstiming = async ( eSlug ) => {
    try {
        if (confirmIportSportstiming === true) {
            setLoading(true);
            await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/event/import/'+eSlug+'/sportstiming');

            setLoading(false)
            //setOpen(false)
        } else {
            alert("Sæt kryds i bekræft spotstiming import.")
        }
    } catch (err) {
        console.error(err);
    }
}

const getAllEventGroups = async ( eSlug ) => {
    try {
        setLoading(true);
        const response = await axiosPrivat.get(process.env.REACT_APP_API_BASEURL + '/event-group/get/all');
        setGroupEvents(response.data);
    } catch (err) {
        setLoading(false);
        console.error(err);
    }
    setLoading(false);
}

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Ændre event</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event navn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="event-name"
                                id="event-name"
                                onChange={(e) => setEventName(e.target.value)}
                                value={eventName}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="event-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Event beskrivelse
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <textarea
                                  rows={8}
                                  name="event-description"
                                  id="event-description"
                                  value={eventDescription}
                                  onChange={(e) => setEventDescription(e.target.value)}
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  required
                                />
                              </div>
                            </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="organisation" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Organisation
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <>
                              {orgName ? 
                              <select
                              id="orginasation"
                              name="orginasation"
                              onChange={(e) => setOrgSlug(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue={orgSlug}
                              >
                              <option key="0" disabled></option>
                              {props.events.map((event) => (
                                <option key={event.id} value={event.slug}>{event.name}</option>
                              ))}
                              </select>

                              : ""}
                             </>
                            </div>
                        </div>


                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="eventgruppe" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Eventgruppe
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <>
                                {!loading ?
                                    <select
                                        id="eventgruppe"
                                        name="eventgruppe"
                                        onChange={(e) => setGroupEventId(e.target.value)}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        defaultValue={groupEventId}
                                    >
                                        <option key="0" value="null"></option>
                                        {groupEvents.map((group_events) => (
                                            <option key={group_events.id} value={group_events.id}>{group_events.name}</option>
                                        ))}
                                    </select>
                                : ""}
                                </>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event prefix
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="event-slug"
                                id="event-slug"
                                onChange={(e) => setEventSlug(e.target.value)}
                                autoComplete="given-name"
                                value={eventSlug}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                                readOnly
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Start dato
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input 
                                type="datetime-local" 
                                id="start-time" 
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setEventStartTime(e.target.value)}
                                value={moment(eventStartTime).format("yyyy-MM-DDTHH:mm")}
                                name="start-time">
                                </input>                           
                              </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Slut dato
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                      
                              <input 
                                type="datetime-local" 
                                id="end-time" 
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setEventEndTime(e.target.value)}
                                value={moment(eventEndTime).format("yyyy-MM-DDTHH:mm")}
                                name="end-time">
                                </input>
                              </div>
                        </div>
                        
                        <>
                        { IsAuth(['Admin', 'Ngo']) ? 
                        
                        <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <div className="text-sm">
                              <label htmlFor="changeMembership" className="font-medium text-gray-700">
                                  Kan tilmelde sig medlemskab
                              </label>
                              <span id="changeMembership-description" className="text-gray-500">
                                <span className="sr-only">Kan tilmelde sig medlemskab</span>
                              </span>
                            </div>
                            <>
                            { showCheckbox ? 
                            <div className="center center h-5">
                              <input
                                id="changeMembership"
                                aria-describedby="changeMembership-description"
                                name="changeMembership"
                                type="checkbox"
                                defaultChecked={canSignUpForMembership}
                                onChange={(e) => setCanSignUpForMembership(e.target.checked)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            : ""}
                            </>
                        </div>
                        : ""}
                        </>

                        <>
                        { IsAuth(['Admin', 'Ngo']) ?
                        <>
                          <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeSignUp" className="font-medium text-gray-700">
                                    Åben for tilmelding
                                </label>
                                <span id="changeSignUp-description" className="text-gray-500">
                                  <span className="sr-only">Åben for tilmelding</span>
                                </span>
                              </div>
                              <>
                              { showCheckbox ? 
                              <div className="center center h-5">
                                <input
                                  id="changeSignUp"
                                  aria-describedby="changeSignUp-description"
                                  name="changeSignUp"
                                  type="checkbox"
                                  defaultChecked={allowSignUp}
                                  onChange={(e) => setAllowSignUp(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              : ""}
                              </>

                            </div>
                            <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeHideOnFrontpage" className="font-medium text-gray-700">
                                    Skjul på forside
                                </label>
                                <span id="changeHideOnFrontpage-description" className="text-gray-500">
                                  <span className="sr-only">Skjul på forside</span>
                                </span>
                              </div>
                              <>
                              { showCheckbox ? 
                              <div className="center center h-5">
                                <input
                                  id="changeHideOnFrontpage"
                                  aria-describedby="changeHideOnFrontpage-description"
                                  name="changeHideOnFrontpage"
                                  type="checkbox"
                                  defaultChecked={hideOnFrontpage}
                                  onChange={(e) => setHideOnFrontpage(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>

                              : ""}
                            </>
                           </div>

                            <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <div className="text-sm">
                                    <label htmlFor="changePublicDonation" className="font-medium text-gray-700">
                                        Public donations
                                    </label>
                                    <span id="changePublicDonation-description" className="text-gray-500">
                                  <span className="sr-only">Public donations</span>
                                </span>
                                </div>
                                <>
                                    { showCheckbox ?
                                        <div className="center center h-5">
                                            <input
                                                id="changePublicDonation"
                                                aria-describedby="changePublicDonation-description"
                                                name="changePublicDonation"
                                                type="checkbox"
                                                defaultChecked={publicDonation}
                                                onChange={(e) => setPublicDonation(e.target.checked)}
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                            />
                                        </div>

                                        : ""}
                                </>
                            </div>

                           <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeIsPublic" className="font-medium text-gray-700">
                                    Aktiver event
                                </label>
                                <span id="changeIsPublic-description" className="text-gray-500">
                                  <span className="sr-only">Aktiver event</span>
                                </span>
                              </div>
                              <>
                              { showCheckbox ? 
                              <div className="center center h-5">
                                <input
                                  id="changeIsPublic"
                                  aria-describedby="changeIsPublic-description"
                                  name="changeIsPublic"
                                  type="checkbox"
                                  defaultChecked={isPublic}
                                  onChange={(e) => setIsPublic(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              : ""}
                            </>
                          </div>
                          </>
                       
                        : ""}
                        </>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                            Billede
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-3">
                                <img
                                className="inline-block h-9 w-9 rounded-full mr-4"
                                src={eventImg + "/36x36"}
                                alt={eventName}
                              />
                                <input  
                                name="image"
                                id="image"
                                type="file"
                                onChange={(e) => handleChange(e.target.files)}
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                />
                            </div>
                        </div>

                        {!loading && IsAuth(['Admin', 'Ngo']) ?
                            <>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div className="text-sm">
                                        <label htmlFor="activateSportstiming" className="font-medium text-gray-700">
                                            Aktiver Sportstiming
                                        </label>
                                        <span id="activateSportstiming-descritpion" className="text-gray-500">
                                      <span className="sr-only">Aktiver Sportstiming</span>
                                    </span>
                                    </div>
                                    <div className="flex justify-center center center h-5">
                                        <input
                                            id="activateSportstiming"
                                            aria-describedby="activateSportstiming-descritpion"
                                            name="activateSportstiming"
                                            type="checkbox"
                                            defaultChecked={activateSportstiming}
                                            onChange={(e) => setActivateSportstiming(e.target.checked)}
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                        />
                                    </div>


                                </div>

                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="sportstiming-event-id"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Sportstiming Event Id
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            type="text"
                                            name="sportstiming-event-id"
                                            id="sportstiming-event-id"
                                            onChange={(e) => setSportstimingEventId(e.target.value)}
                                            value={sportstimingEventId}
                                            autoComplete="sportstiming-event-id"
                                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="sportstiming-event-api-key"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Sportstiming Event Api Key
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            type="text"
                                            name="sportstiming-event-api-key"
                                            id="sportstiming-event-api-key"
                                            onChange={(e) => setSportstimingEventApiKey(e.target.value)}
                                            value={sportstimingEventApiKey}
                                            autoComplete="sportstiming-event-api-key"
                                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div className="text-sm">
                                        <label htmlFor="changeDelete" className="font-medium text-gray-700">
                                            Bekræft import fra sportstiming
                                        </label>
                                        <span id="changeDelete-description" className="text-gray-500">
                                      <span className="sr-only">Bekræft import fra sportstiming</span>
                                    </span>
                                    </div>
                                    <div className="flex justify-center center center h-5">
                                        <input
                                            id="changeDelete"
                                            aria-describedby="changeDelete-description"
                                            name="changeDelete"
                                            type="checkbox"
                                            defaultChecked={confirmIportSportstiming}
                                            onChange={(e) => setConfirmIportSportstiming(e.target.checked)}
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="w-50 flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => {
                                            importSportstiming(eventSlug)
                                        }}
                                    >
                                        Import fra sportstiming
                                    </button>
                                </div>
                            </>
                        : ""}
                        {!loading && IsAuth(['Admin']) ?
                            <>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div className="text-sm">
                                        <label htmlFor="changeDelete" className="font-medium text-gray-700">
                                            Bekræft sletning
                                        </label>
                                        <span id="changeDelete-description" className="text-gray-500">
                                      <span className="sr-only">Bekræft sletning</span>
                                    </span>
                                    </div>
                                    <div className="flex justify-center center center h-5">
                                        <input
                                            id="changeDelete"
                                            aria-describedby="changeDelete-description"
                                            name="changeDelete"
                                            type="checkbox"
                                            defaultChecked={allowDelete}
                                            onChange={(e) => setAllowDelete(e.target.checked)}
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="w-50 flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => deleteEvent(eventSlug)}
                                    >
                                        Slet Event
                                    </button>
                                </div>
                            </>
                            : ""}

                            { IsAuth(['Admin', 'Ngo']) && eventClosedAt == null ?
                                <>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <div className="text-sm">
                                            <label htmlFor="afslutEventet" className="font-medium text-gray-700">
                                                Afslut eventet
                                            </label>
                                            <span id="afslutEventet-descritpion" className="text-gray-500">
                                      <span className="sr-only">Afslut Event</span>
                                    </span>
                                        </div>
                                        <div className="flex justify-center center center h-5">
                                            <input
                                                id="afslutEventet"
                                                aria-describedby="afslutEventet-descritpion"
                                                name="afslutEventet"
                                                type="checkbox"
                                                defaultChecked={afslutEventet}
                                                onChange={(e) => setAfslutEventet(e.target.checked)}
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                            />
                                        </div>


                                    </div>

                                </>
                                : ""}

                            { IsAuth(['Admin', 'Ngo']) && eventClosedAt != null ?
                                <>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <div className="text-sm">
                                            <label htmlFor="afslutEventet" className="font-medium text-gray-700">
                                                Afsluttet event
                                            </label>
                                            <span id="afslutEventet-descritpion" className="text-gray-500">
                                      <span className="sr-only">Afsluttet event</span>
                                    </span>
                                        </div>
                                        <div className="flex justify-center center center h-5">
                                            {moment(eventClosedAt).format("yyyy-MM-DDTHH:mm")}
                                        </div>


                                    </div>

                                </>
                                : ""}

                        </div>
                    </div>


                    </div>
                    <>
                        {!loading ?
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="submit"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                >
                                    Gem
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => {
                                        setOrgName(false);
                                        setOpen(false)
                                    }}
                                    ref={cancelButtonRef}
                                >
                                    Annuller
                                </button>
                            </div>
                            : <PacmanLoader
                                color="#26103c"
                                cssOverride={{margin: "20px auto"}}
                                loading
                                margin={0}
                                size={24}
                                speedMultiplier={1}
                            />
                        }
                    </>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}