import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ButtonStandard from '../../utillityComponents/ButtonStandard'
import IsAuth from '../../utillityComponents/IsAuth'
import moment from 'moment'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AllEventList(props) {

  return (
      <>
    <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
    {props.title}
  </h2>
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th>

                    </th>
                    <th scope="col" className="py-3.5 pl-0 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                      Navn
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Start tidspunkt
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Slut tidspunkt
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Slug
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Rediger
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.events.sort((a, b) => a.name > b.name ? 1 : -1).map((event) => (
                    <Fragment key={event.name}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={6}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {event.name}

                         {/*  <Link to="/" className="text-indigo-600 hover:text-indigo-900 float-right">
                              Edit<span className="sr-only">, {event.name}</span>
                            </Link> */}
                        </th>
                      </tr>
                      {event.events.map((events, eventsIdx) => (
                        <tr
                          key={events.id}
                          className={classNames(eventsIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td
                           className='py-2 block h-9 w-9 rounded-full ml-4 mr-3'
                          >
                          <img
                            className="block h-9 w-9 rounded-full ml-4"
                            src={events.img + "/36x36"}
                            alt={events.name}
                          />
                          </td>
                          <td className="whitespacepy-4 pl-0 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                          <Link to={"/events/"+events.slug} className="text-indigo-600 hover:text-indigo-900">
                            {events.name}
                          </Link>
                            <br></br>
                            <span className={events.event_group != null ? 'inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800' : ''}>
                              {events.event_group != null ? 'Eventgruppe: ' + events.event_group : ''}
                            </span>
                            {events.app_codes && (
                              <>
                                <br></br>
                                <span className="text-xs text-gray-500">
                                  App Code: {events.app_codes.join(', ')}
                                </span>
                              </>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(events.event_start_date).format("dddd, DD MMM HH:mm YYYY")}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(events.event_end_date).format("dddd, DD MMM HH:mm YYYY")}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{events.slug}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {IsAuth(['Admin', 'Ngo', 'Org_admin']) ?
                            <>
                            <button
                            key={event.name + "_edit"}
                              type="button"
                              className= "text-indigo-600 hover:text-indigo-900"
                              onClick= {() => props.openPopupUpdateEvent(events.slug)}
                            >
                              Event
                            </button>
                            </>
                            : ""}
                            {IsAuth(['Admin', 'Ngo']) ?
                            <>
                            <br></br>
                            <br></br>
                            <button
                            key={event.name + "_edit_route"}
                              type="button"
                              className= "text-indigo-600 hover:text-indigo-900"
                              onClick= {() => props.openPopupUpdateRoute(events.slug)}
                            >
                              Ruter
                            </button>
                            </>
                            : ""}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {IsAuth(['Admin', 'Ngo']) ?
              <ButtonStandard text={'Tilføj event'} size="medium" click={props.openPopup} />
              : ''
            }
       {IsAuth(['Admin', 'Ngo']) ?
              <ButtonStandard text={'Tilføj Organisation'} size="medium" click={props.openPopupOrg} />
              : ''
            }
    </div>
    </>
  )
}